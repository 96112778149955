.row {
    margin-bottom: 20px;    
    display: flex;
    align-items: center;
}

span {
    display: inline-flex;
    margin-right: 15px;
    min-width: 100px;
}
h1 {
  color: #ffffff;
  padding-left: 50px;
}
h3 {
  color: dimgrey;
}
.number {
    width: 40px;
    margin-right: 15px;
}

.smallImp {
  margin-left: 3px;
  width: 40px;
  padding: 5px;
}

.seeds_peers {
    width: 400px;
    height: 200px;
    word-break: break-word;
}
.prun {
  width: 50px;
  padding: 5px;
}
.denom {
  width: 50px;
  padding: 5px;
}
.normale {
  width: 400px;
  padding: 5px;
}
.normale2 {
  width: 325px;
  padding: 5px;
}
.normale3 {
  margin-left: 15px;
  width: 75px;
  padding: 5px;
}
.normale4 {
  width: 28px;
  padding: 5px;
}
.comment {
  color: rgb(184, 180, 180);
  padding: 6px;
  padding-top: 21px;
}
.command{
  padding: 15px;
  border: 1px solid #6e6e6e;
}
.cch_main {
  padding: 35px;
  margin: 15px;
  min-width: 550px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  border: 1px solid #fa6400;
  text-decoration: none;  
}
.tochange{
  color: #fa6400;
  padding: 0;
  margin: 0;
}
.cch_link {
  text-decoration: none;
}